import { graphql } from 'gatsby';

import React, { Component } from 'react';
import { get, find } from 'lodash';

import Layout from '../layout/Layout';

import { SectionTitle, PageHero, ArticlesSection } from '../common/components';

interface Props {
  data: any;
  location: any;
}

class PressAndMedia extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const pressAndMediaTitle = get(data, 'page.data.title', null);
    const body = get(data, 'page.data.body');
    const heroData = find(body, { __typename: 'PrismicPressAndMediaBodyHero' });
    const articlesData = find(body, {
      __typename: 'PrismicPressAndMediaBodyArticles',
    });

    return (
      <Layout data={data} location={this.props.location}>
        {heroData && <PageHero data={heroData} />}
        {pressAndMediaTitle && <SectionTitle title={pressAndMediaTitle} />}
        {articlesData && (
          <ArticlesSection
            data={articlesData}
            displayFilters
            lang={get(data, 'page.lang', 'en-us')}
          />
        )}
      </Layout>
    );
  }
}

export default PressAndMedia;

export const query = graphql`
  query PressAndMediaQuery($slug: String!) {
    page: prismicPressAndMedia(id: { eq: $slug }) {
      ...PressAndMedia
    }
  }
`;
